import React from "react"
import { navigate } from "gatsby"

import FullSpecs from "../components/organisms/FullSpecs"
import Layout from "../components/Layout"
import Seo from "../components/seo"

const FullSpecsPage = () => {
  return (
    <Layout>
      <Seo title="Full specs" />
      <FullSpecs closeMethod={() => navigate("/")} />
    </Layout>
  )
}

export default FullSpecsPage
