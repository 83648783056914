import React from "react"
import styled from "styled-components"
import HeaderText from "../../atoms/HeaderText"
import P from "../../atoms/P"
import useMediaType from "../../hooks/useMediaType"
import { graphql, useStaticQuery } from "gatsby"

export const Container = styled.div`
  border-top: 20px solid ${props => props.theme.colors.site.secondaryLight};
  line-height: 0px;
  display: flex;
  width: 100%;
  padding: 20px;
  position: relative;
  flex-direction: column;
  //mobile
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    padding: 64px;
  }
`
const X = styled.div`
  position: absolute;
  top: 4.5%;
  right: 4%;
  transform: scale(0.8);

  background: url("/x-icon.svg");
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    top: 13%;
    right: 9%;
  }
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopMin}px) {
    top: 12.5%;
    right: 6%;
  }
`
const SpecsWrapper = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;

  ul {
    margin-top: 32px;
    max-height: 320px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    &:last-of-type {
      margin-top: 20px;
    }
  }
  li {
    &::marker {
      color: ${props => props.theme.colors.textPrimaryColor};
      font-size: 0.6em;
    }
    p {
      line-height: 18px;
      margin: 0;
      margin-bottom: 4px;
    }
    line-height: 24px;
  }
  height: 100%;
  display: flex;
  //mobile
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    width: 50%;
  }
`
const Item = styled.div`
  display: flex;

  background: ${props => props.theme.colors.backgroundSecondary};
  margin-bottom: 8px;
  flex-direction: column;
  //mobile
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    flex-direction: row;
  }
`
const HeaderWrapper = styled.div`
  margin: 11px auto 11px auto;

  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin: 62px auto 40px auto;
  }
  //desktopsmall
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin: 40px auto 56px auto;
  }
  //desktop
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    margin: 40px auto 56px auto;
  }
`
const ItemHeaderWrapper = styled.div`
  display: flex;

  padding: 8px;
  margin-bottom: -35px;
  p {
    margin-right: auto;
    line-height: 24px;

    @media only screen and (min-width: ${props =>
        props.theme.screenBreakpoints.tabletMin}px) {
      margin-bottom: 0;
    }
  }
  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-bottom: 0;
    padding-left: 22px;
    width: 50%;
    align-items: center;
  }
  //desktopsmall
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    padding-left: 100px;
  }
`
const FullSpecs = ({ items, closeMethod }) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "full_specs" } } }
        ) {
          edges {
            node {
              frontmatter {
                type
                items {
                  Name
                  Content
                }
              }
            }
          }
        }
      }
    `
  )
  const mediaType = useMediaType()
  const headerSizeHandler = media => {
    switch (media) {
      case "desktop":
        return 4
      case "desktopSmall":
        return 4
      case "tablet":
        return 4
      case "mobile":
        return 5
      default:
        return 5
    }
  }
  const itemHeaderSizeHandler = media => {
    switch (media) {
      case "desktop":
        return "regular"
      case "desktopSmall":
        return "regular"
      case "tablet":
        return "regular"
      case "mobile":
        return "medium"
      default:
        return "medium"
    }
  }
  const itemPSizeHandler = media => {
    switch (media) {
      case "desktop":
        return "medium"
      case "desktopSmall":
        return "medium"
      case "tablet":
        return "medium"
      case "mobile":
        return "small"
      default:
        return "small"
    }
  }
  return (
    <Container>
      {allMarkdownRemark.edges.map((item, i) => {
        let items = item.node.frontmatter.items

        return (
          <>
            <X onClick={closeMethod} />
            <HeaderWrapper>
              <HeaderText size={headerSizeHandler(mediaType)} color="text">
                Full Specs
              </HeaderText>
            </HeaderWrapper>
            {items.map(item => (
              <>
                {!item.Content ? (
                  ""
                ) : (
                  <Item>
                    <ItemHeaderWrapper>
                      <HeaderText
                        color="text"
                        size={itemHeaderSizeHandler(mediaType)}
                      >
                        {item.Name.toUpperCase()}
                      </HeaderText>
                    </ItemHeaderWrapper>
                    <SpecsWrapper>
                      <ul>
                        {item.Content.map(content => (
                          <>
                            <li>
                              <P
                                size={itemPSizeHandler(mediaType)}
                                color="text"
                              >
                                {content}
                              </P>
                            </li>
                          </>
                        ))}
                      </ul>
                    </SpecsWrapper>
                  </Item>
                )}
              </>
            ))}
          </>
        )
      })}
    </Container>
  )
}

export default FullSpecs
